<template>
  <div>
    <div class="news">
      <div class="title">
        GV20 Therapeutics Announces Oral Presentation on GV20-0251 for Solid Tumors Targeting IGSF8 at AACR Annual Meeting 2024
      </div>
      <div class="time">
        Mar 5, 2024
      </div>
      <div class="content">
        
        <p>
          Cambridge, Massachusetts – March 5, 2024 – GV20 Therapeutics, a clinical-stage biotechnology company integrating AI, genomics, and disease biology to create next-generation antibody therapeutics for cancer, announced today that its abstract was selected for an oral presentation at the American Association of Cancer Research (AACR) Annual Meeting 2024, taking place April 5-10, 2024, in San Diego, CA. 
        </p>
        <p>
          Details of the oral presentation are below:
        </p>
        <p>
          Title: “IGSF8 is a novel innate immune checkpoint and cancer immunotherapy target”<br>
Session Type: Minisymposium<br>
Session Category: Immunology<br>
Session Title: Immune Targets and Therapies<br>
Session Date/Time: Monday Apr 8, 2024, 2:30 PM - 4:30 PM<br>
Abstract Presentation Number: 3914<br>
        </p>
        <p>
          More information can be found on the 2024 AACR <a style="color:#2d8cf0;" href="https://www.aacr.org/meeting/aacr-annual-meeting-2024/">website</a>.
        </p>
        <p>
          <div class="font-weight-bold">
            About GV20 Therapeutics
          </div>
          At GV20 Therapeutics, our mission is to develop a differentiated pipeline of innovative antibody therapeutics to transform the treatment of cancer.  We leverage our in-house STEAD platform (Simultaneous Target Evaluation and Antibody Discovery), which integrates AI, genomics, and disease biology to identify novel drug targets and create next-generation therapies against these targets.  Our lead program, GV20-0251, a first-in-class, fully human, Fc-attenuated IgG1 monoclonal antibody targeting IGSF8, is in a multi-center Phase I clinical trial in patients with advanced solid tumors (<a style="color:#2d8cf0;" href="https://classic.clinicaltrials.gov/ct2/show/NCT05669430">NCT05669430</a>). GV20 is headquartered in Cambridge, MA. 
        </p>
        <p>
          To learn more about GV20, please visit <a style="color:#2d8cf0;" href="https://mobile.gv20tx.com/">https://gv20tx.com/</a> and follow the company on <a style="color:#2d8cf0;" href="https://www.linkedin.com/company/gv20tx/">LinkedIn</a>.
        </p>

        <p>
          <div class="font-weight-bold">
            Business Contact
          </div>
          Ying Gong, Ph.D., CBO<br>
          BD@gv20tx.com
        </p>

        <p>
          <div class="font-weight-bold">
            Investor and Media Contact
          </div>
          Amy Conrad<br>
Juniper Point<br>
amy@juniper-point.com<br>
        </p>

      </div>
    </div>
    <Footers />
  </div>
</template>
<script>
import Footers from '@/components/Footers'
export default {
  data () {
    return {

    }
  },
  components: {
    Footers
  }
}
</script>
<style scoped lang="scss">
.news {
  font-size: 30px;
  font-weight: 500;
  padding: 0 40px;
  .title {
    font-weight: bold;
    font-size: 40px;
    padding-top: 40px;
  }
  .time {
    font-size: 20px;
    color: #666666;
    font-style: italic;
    margin: 50px 0;
  }
  .item {
    .font-weight-bold {
      margin-bottom: 30px;
    }
  }
}
</style>
